export const environment = {
  production: true,
  development: false,
  test: false,
  brand: 'Vyv',
  apiV2RootUrl: 'https://tst-02.assurance.vyv-conseil.fr/api/',
  baseUrl: 'https://tst-02.assurance-habitation.vyv-conseil.fr/',
  matomotoKey: 'CHANGEME',
  appInsightsKey: 'ab0508f5-e01f-4f79-8db0-5f7c95fd83a4',
  assConfort: 'https://saboiartbackofficeso90j.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Confort_Tableau%20des%20garanties.pdf',
  assMetropole: 'https://saboiartbackofficeso90j.blob.core.windows.net/public/Plaquette_Assurance_Habitation_{0}_Tableau%20des%20garanties.pdf',
  assSolidaire:
    'https://saboiartbackofficeso90j.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Solidaire_Tableau%20des%20garanties.pdf',
  matomoSiteId: 6,
  matomoHostUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/',
  matomoScriptUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/js/container_682wvypB.js',
  modeleCourrierResiliation: 'https://saboiartbackofficeso90j.blob.core.windows.net/public/ModeleCourrier.docx',
  msalConfig: {
    auth: {
      clientId: '04aa4778-310e-4fc8-ba2c-7c1922aa1324',
      authority: 'https://login.microsoftonline.com/6f12f368-4cb1-4b07-9574-dd2cdf95d9c1',
      redirectUri: 'https://tst-02.assurance-habitation.vyv-conseil.fr/'
    }
  },
  apiConfig: {
    scopes: ['api://boiard-tst-02-back-office/Read.All', 'api://boiard-tst-02-back-office/Write.All'],
    uri: 'https://tst-02.assurance.vyv-conseil.fr/api/'
  }
};
